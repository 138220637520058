import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import history from '../../../history';
import { logOut, resetErrors, resetOldFamilyDetails } from '../../../actions';

import { ReactComponent as CustomerDetailsTaskActive } from '../../../assets/icons/sideMenu/customer-details-task-active.svg';
import { ReactComponent as CustomerDetailsTaskInactive } from '../../../assets/icons/sideMenu/customer-details-task-inactive.svg';

import { ReactComponent as ConsultationTaskActive } from '../../../assets/icons/sideMenu/consultation-task-active.svg';
import { ReactComponent as ConsultationTaskInactive } from '../../../assets/icons/sideMenu/consultation-task-inactive.svg';

import { ReactComponent as AdminTaskActive } from '../../../assets/icons/sideMenu/admin-task-active.svg';
import { ReactComponent as AdminTaskInactive } from '../../../assets/icons/sideMenu/admin-task-inactive.svg';

import { ReactComponent as SalesTaskActive } from '../../../assets/icons/sideMenu/sales-task-active.svg';
import { ReactComponent as SalesTaskInactive } from '../../../assets/icons/sideMenu/sales-task-inactive.svg';

import { ReactComponent as TicketingTaskActive } from '../../../assets/icons/sideMenu/ticketing-task-active.svg';
import { ReactComponent as TicketingTaskInactive } from '../../../assets/icons/sideMenu/ticketing-task-inactive.svg';
import { ReactComponent as CareIcon } from '../../../assets/icons/sideMenu/care-health-insurance-logo.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/sideMenu/logout.svg';


import { ReactComponent as CheckInActiveIcon } from '../../../assets/icons/sideMenu/checkin-active.svg';
import { ReactComponent as CheckInInActiveIcon } from '../../../assets/icons/sideMenu/checkin-inactive.svg';

import {ReactComponent as DoctorActiveIcon} from '../../../assets/icons/sideMenu/doctor-active.svg';
import {ReactComponent as DoctorInactiveIcon} from '../../../assets/icons/sideMenu/doctor-inactive.svg';

import { accessRole } from '../../../common/constants';
import css from './style.module.scss';
import { clientRoute } from '../../../constants';

class SideMenuBar extends React.Component {

	logOut = () => {
		this.props.logOut();
	};

	getSideMenuItems = () => {
		const sideMenuItems = [
			{ route: clientRoute.HOME_PAGE, icon: [<CustomerDetailsTaskInactive />, <CustomerDetailsTaskActive />] },
			{ route: clientRoute.CONSULTATIONS_TODAY_PAGE, icon: [<ConsultationTaskInactive />, <ConsultationTaskActive />] },
			// clientRoute.PRODUCTS_PAGE },
			{ route: clientRoute.SALES_PAGE, icon: [<SalesTaskInactive />, <SalesTaskActive />] },
			{ route: clientRoute.TICKETING_PAGE, icon: [<TicketingTaskInactive />, <TicketingTaskActive />] },
			{ route: clientRoute.VISITS_PAGE, icon: [<CheckInInActiveIcon />, <CheckInActiveIcon />] },
			{ route: clientRoute.DOCTORS_PAGE, icon: [<DoctorInactiveIcon />, <DoctorActiveIcon />] }
		];
		if ((this.props.auth && this.props.auth.authDetails && this.props.auth.authDetails.data && this.props.auth.authDetails.data.user && !_.isNil(_.find(this.props.auth.authDetails.data.user.roles, { name: accessRole.OPS_MANAGER })))) {
			sideMenuItems.push(
				{ route: clientRoute.ADMIN_PAGE, icon: [<AdminTaskInactive />, <AdminTaskActive />] }
			)
			sideMenuItems.push(
				{ route: clientRoute.CARE_DOC_UPLOAD_PAGE, icon: [<CareIcon />, <CareIcon />] }
			)
		}
		return sideMenuItems;
	}

	setClientRoute = (item) => {
		this.props.resetOldFamilyDetails();
		history.push(item);
	};

	render() {
		let currentRoute = window.location.pathname;
		return (
			<div className={css.sidebar_container}>
				{this.getSideMenuItems().map((item) => {
					return (
						<div
							key={item.route}
							className={currentRoute === item.route ? css.sidebar_container_item_selected : css.sidebar_container_item_unselected}
							onClick={() => this.setClientRoute(item.route)} >
							<span>{item.icon[currentRoute === item.route ? 1 : 0]}</span>
						</div>
					)
				})}
				<div className={css.sidebar_container_item_unselectable} onClick={this.logOut}>
					<span><LogoutIcon /></span>
				</div>
			</div>

		)
	}
}

const mapStateToProps = (state) => {
	return { auth: state.auth, familyDetails: state.familyDetails, error: state.error };
};

export default connect(mapStateToProps, { logOut, resetErrors, resetOldFamilyDetails })(SideMenuBar);